import React, { useState } from 'react';
import { useAPI } from '../api';
import store, { actions, getters } from '../../redux/store';
import WebFn, { callAppFunc } from '../jsbridge/index';
import { useSelector } from 'react-redux';
import { encode, decode } from 'js-base64';

const useProvideMount = () => {
  const api = useAPI();
  // if develop
  const token = useSelector((state) => getters.getToken(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));

  useState(() => {
    // location login
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.GATSBY_INAPP === 'false'
    ) {
      const username = process.env.GATSBY_DEV_USERNAME;
      const password = process.env.GATSBY_DEV_PASSWORD;
      const deviceid = process.env.GATSBY_DEV_DEVICEID;

      if (token === undefined) {
        // api
        //   .getInfo()
        //   .then((res) => {
        //     if (!res) return;
        //     console.log('get info =', res);
        //   })
        //   .catch((error) => {
        //     console.log('get info exception =', error);
        //   });
        var auth = '';
        var access_token = '';
        var refresh_token = '';
        var skey = '';
        var customerNo = '';
        api
          .login(username, password, deviceid)
          .then((res) => {
            if (!res) {
              return;
            }
            console.log('login res', res);
            auth = res['qc-Auth'];
            access_token = res.access_token;
            refresh_token = res.refresh_token;
            console.log('refresh_token', refresh_token);
            skey = res.skey;
            customerNo = res.customerNo;

            api.updateToken(auth, access_token, '', refresh_token);
            store.dispatch(actions.setAccessToken(access_token));
            store.dispatch(actions.setToken(auth));
            store.dispatch(actions.setHashAccount('0975123456'));
            store.dispatch(
              actions.setUserInfo({
                ...userInfo,
                skey: skey,
                customerNo: customerNo,
              })
            );
            api
              .getCookie()
              .then((res) => {
                console.log('cookie res', res);
                api.updateToken(auth, access_token, res.cookie, refresh_token);
                store.dispatch(actions.setStoreId('059'));
                store.dispatch(actions.setCookie(res.cookie));

                api
                  .setShippingMethod()
                  .then((res) => {})
                  .catch((error) => {
                    console.log('setShippingMethod exception =', error);
                  });
              })
              .catch((error) => {
                console.log('cookie exception =', error);
              });
          })
          .catch((error) => {
            console.log('login exception =', error);
          });
      }
    } else if (process.env.GATSBY_INAPP === 'true') {
      const jsBridgeCallback = (strJson) => {
        const dataJson = JSON.parse(strJson);
        if (dataJson.fnName === 'sendLoginData') {
          const newCooike = window.atob(dataJson.params.cookie);
          api.updateToken(
            dataJson.params.token,
            dataJson.params.access_token,
            newCooike
          );
          store.dispatch(actions.setToken(dataJson.params.token));
          store.dispatch(actions.setCookie(newCooike));
          store.dispatch(actions.setStoreId(dataJson.params.storeId));
          store.dispatch(actions.setAccessToken(dataJson.params.access_token));
          // store.dispatch(actions.setHashAccount('0975123456'));
          store.dispatch(actions.setHashAccount(dataJson.params.hash_account));
          store.dispatch(
            actions.setUserInfo({
              ...userInfo,
              skey: dataJson.params.skey,
              customerNo: dataJson.params.customerNo,
            })
          );
        }
        if (dataJson.fnName === 'sendSuccessedOrderCount') {
          store.dispatch(actions.setSuccessOrderCount(dataJson.params.count));
        }
      };
      WebFn({ callback: jsBridgeCallback });
      callAppFunc('getSuccessedOrderCount', {});
      if (token === undefined) {
        callAppFunc('fetchLoginData', []);
      }
    }

    // set session storage
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('appIsCheck', true);
    }
  }, []);
};

export default useProvideMount;
