import React, { useEffect, useState } from 'react';

export default function PdpBanner({ isShow }) {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(isShow);
  }, [isShow]);

  return show && <div id="hp-banner"></div>;
}
