import React, { useEffect, useState } from 'react';
import { useSelectableList } from 'rooks';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import parse from 'html-react-parser';
import { navigate } from 'gatsby';
import { setMetaViewItem, setMetaAddToCart } from '../../utils/_meta';
import ContentLoader from 'react-content-loader';
import { Tooltip } from 'react-tooltip';

import SwiperLightBox from 'components/swiper-lightbox';
import CollapseCard from 'components/collapse-card';
import SpecInfo from 'components/spec-info';
import HeaderBar from 'components/header-bar';
import { logBQ } from 'utils/_omsLog';

import { useAPI } from 'utils/api';
import { showLoading, dismissLoading } from 'utils/loading';
import { callAppFunc } from 'utils/jsbridge/index.js';
import { setViewItem, setAddToCart } from 'utils/_ga';
import {
  setAppsFlyerContentView,
  setAppsFlyerAddToCart,
} from 'utils/_appsflyer';
import { pageCheck } from '../../utils/pageCheck';

import imageRoundPlusBlue from 'images/icons/i-plus-round-blue.svg';
import imageRoundPlusGray from 'images/icons/i-plus-round-gray.svg';
import imageRoundMinusBlue from 'images/icons/i-minus-round-blue.svg';
import imageRoundMinusGray from 'images/icons/i-minus-round-gray.svg';
import PdpBanner from 'components/AD/PdpBanner';

const imageMinusActive = true;
const imagePlusActive = true;

let product_count = 1;

const ProductPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 450;
  const ratio = viewWidth / mainWidth;
  console.log(viewWidth);
  return (
    <>
      <ContentLoader
        width={450 * ratio}
        height={1000}
        viewBox={`0 0 ${450 * ratio} 1000`}
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
      >
        <rect
          x={32 * ratio}
          y="15"
          rx={10 * ratio}
          ry="10"
          width={388 * ratio}
          height="217"
        />
        {[...Array(10)].map((i, index) => {
          return (
            <>
              <rect
                key={index}
                x={32 * ratio}
                y={242 + index * 66 + (index !== 0 ? 10 * index : 0)}
                rx={4 * ratio}
                ry="4"
                width={350 * ratio}
                height="9"
              />
              {[...Array(5)].map((j, subIdx) => (
                <rect
                  x={32 * ratio}
                  y={
                    261 +
                    subIdx * 10 +
                    index * 66 +
                    (index !== 0 ? 10 * index : 0)
                  }
                  rx={3 * ratio}
                  ry="3"
                  width={250 * ratio}
                  height="6"
                />
              ))}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const ProductDetail = ({ location, ProductId }) => {
  const api = useAPI();
  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const hashAccount = useSelector((state) => getters.getHashAccount(state));
  const [
    selectionOutofstock,
    { matchSelection: defaultOutofstock, toggleSelection: toggleOutofstock },
  ] = useSelectableList([1, 2, 3], 0);

  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line unicorn/consistent-function-scoping

  const openLightBoxEvent = (value) => {
    setIsOpen(value);
  };

  const [product, setProduct] = useState({});
  const [productCount, setProductCount] = useState(1);
  const [initProductCount, setInitProductCount] = useState(1);
  const [display, setDisplay] = useState(false);
  const [cartNum, setCartNum] = useState(0);

  const [specData, setSpecData] = useState([]);
  const pageName = '商品內頁';

  // getters中取得attributionToken
  const attributionToken = useSelector((state) =>
    getters.getAttributionToken(state)
  );

  const getInfo = () => {
    api
      .getInfo()
      .then((res) => {
        const num = res.cart.count;
        setCartNum(num);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addQuantity = () => {
    if (product_count + 1 > product.c_maxOrderQuantity) {
      alert('已達商品購買上限');
    } else {
      product_count = product_count + 1;
    }

    setProductCount(product_count);
  };

  const deleteQuantity = () => {
    if (product_count > 0 && product_count >= product.minOrderQuantity) {
      product_count = product_count - 1;
    }
    if (product_count == 0) return;
    setProductCount(product_count);
  };

  const addToCart = () => {
    api
      .addCart({
        productId: ProductId,
        quantity: product_count,
        itemOutofstockAction: selectionOutofstock[1],
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        if (!res) {
          setProductCount(initProductCount);
          return;
        }
        let data = {
          ...product,
          currency: 'TWD',
          salePrice: product.salePrice,
          price: product.price,
          name: product.name,
          item_name: product.item_name,
          spec: product.c_Specification,
          count: product_count,
          id: ProductId,
        };
        setAddToCart(hashAccount, data, pageName, '', attributionToken);
        // appsflyer
        setAppsFlyerAddToCart(data);
        setMetaAddToCart(data);
        window.popup('加入成功');
        getInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    product_count = 1;
    showLoading();

    api
      .getProduct(ProductId)
      .then((res) => {
        try {
          logBQ({
            device: 'appweb',
            account: userInfo.mobile ?? 'none',
            ms: 0,
            api_name: 'Api-getProductDetail',
            api_data_name: 'all',
            log: JSON.stringify(res),
          });
          dismissLoading();
          setProduct(res);
          const arrSpec = res.specDescription.map((element) => ({
            title: element.label,
            content: element.value[0],
            id: element.id,
          }));
          setSpecData(arrSpec);
          setDisplay(true);

          // GA
          setViewItem(hashAccount, res, ProductId, pageName, attributionToken);
          // appsflyer
          setAppsFlyerContentView(res);
          setMetaViewItem(res, ProductId);
        } catch (error) {
          console.log(error);
          alert('商品資訊錯誤');
          logBQ({
            device: 'appweb',
            account: userInfo.mobile ?? 'none',
            ms: 0,
            api_name: 'Api-getProductDetail',
            api_data_name: 'error',
            log: JSON.stringify(error),
          });

          dismissLoading();
          setDisplay(true);
        }
      })
      .catch((error) => {
        dismissLoading();
      });

    getInfo();
  }, []);

  return (
    <>
      <div className={`product-detail ${isOpen ? 'prevent-scroll' : ''}`}>
        <HeaderBar
          transparent
          showCart
          cartCountExternal={cartNum}
          onCartClick={() => {
            if (cartNum > 0) {
              pageCheck(api, () => {
                localStorage.removeItem('searchQuery');
                navigate('/cart', {
                  state: { page: pageName },
                  replace: true,
                });
              });
            } else {
              alert('還沒有加入商品喔~');
            }
          }}
          goBack={() => {
            callAppFunc('backHome', {});
          }}
        />
        {display ? (
          <div>
            {product && product.imageGroups && (
              <SwiperLightBox
                product={product}
                images={product.imageGroups[0].images}
                open={openLightBoxEvent}
              />
            )}

            <div className="product-title">
              <div className="title">{product.name}</div>
              <Tooltip
                anchorSelect=".product-title .title"
                place="bottom-start"
                noArrow
                style={{ maxWidth: '80%' }}
              >
                {product.name}
              </Tooltip>
              <span>◎銷1售入數：{product.c_packageQty}入</span>
              <span>◎規格：{product.c_Specification}</span>
            </div>

            {product.salePrice !== product.price ? (
              <div className="product-price">${product.salePrice}</div>
            ) : (
              <div className="product-price">${product.price}</div>
            )}

            {product.productPromotions?.length > 0 &&
              product.productPromotions.find(
                (promotion) => promotion.promotionName !== ''
              ) && (
                <div className="cart-showDetail">
                  <span className="showcontent">
                    {
                      product.productPromotions.find(
                        (promotion) => promotion.promotionName !== ''
                      )?.promotionName
                    }
                  </span>
                </div>
              )}
            <PdpBanner isShow={true} />
            <div className="product-info">
              <CollapseCard title="商品資訊 " expand={true}>
                {product.c_productSpecialty !== '' && (
                  <div>
                    {product.c_productSpecialty}
                    <br></br>
                    <br></br>
                  </div>
                )}
                {parse(product.shortDescription + '')}
                <br></br>
                {parse(product.longDescription + '')}
                <br></br>
                {parse(product.c_ingredients + '')}
                <br></br>
                {parse(product.c_nutritionalValues + '')}
              </CollapseCard>
              <CollapseCard title="規格介紹 " expand={false}>
                <SpecInfo data={specData} />
              </CollapseCard>
              <CollapseCard title="配送及售後服務說明" expand={false}>
                {parse(product.serviceDescription + '')}
              </CollapseCard>
              <div className="product-setting">
                <div className="title">此商品缺貨時...</div>
                <div>
                  <input
                    type="radio"
                    id="remove"
                    name="setting"
                    value="remove"
                    checked={defaultOutofstock({ index: 0 })}
                    onChange={() => toggleOutofstock({ index: 0 })()}
                  />
                  <label htmlFor="remove">
                    <span>
                      <span></span>
                    </span>
                    <div>缺貨刪除</div>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="delete"
                    name="setting"
                    value="delete"
                    onChange={() => toggleOutofstock({ index: 1 })()}
                  />
                  <label htmlFor="delete">
                    <span>
                      <span></span>
                    </span>
                    <div>換貨</div>
                  </label>
                  <label htmlFor="delete">
                    <span>
                      <span></span>
                    </span>
                    <div>（更換同系列其他商品或他牌相同口味）</div>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="cancel"
                    name="setting"
                    value="cancel"
                    onChange={() => toggleOutofstock({ index: 2 })()}
                  />
                  <label htmlFor="cancel">
                    <span>
                      <span></span>
                    </span>
                    <div>整單取消</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ProductPreview />
        )}
        <div className="bottom-block">
          <div className="plus-minus">
            <img
              src={imageMinusActive ? imageRoundMinusBlue : imageRoundMinusGray}
              onClick={deleteQuantity}
            />
            <div>{productCount}</div>
            <img
              src={imagePlusActive ? imageRoundPlusBlue : imageRoundPlusGray}
              onClick={addQuantity}
            />
          </div>
          <div className="cart-button" onClick={addToCart}>
            加入購物車
          </div>
        </div>
      </div>
      <style jsx>{`
        .product-detail {
          padding-bottom: 100px;
          &.prevent-scroll {
            height: calc(100vh - 100px);
            overflow-y: hidden;
          }
          .swiper-block {
            height: 200px;
          }
          .product-title {
            width: calc(100vw - 40px);
            margin: 30px auto 0 auto;
            .title {
              font-weight: bold;
              font-size: 24px;
              line-height: 35px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            span {
              font-size: 14px;
              line-height: 20px;
              color: #999999;
              margin-right: 14px;
            }
          }
          .product-price {
            font-size: 24px;
            line-height: 35px;
            color: #333333;
            margin: 15px 0 0 20px;
            font-weight: 700;
          }

          .product-info {
            padding: 15px;

            .product-setting {
              .title {
                font-weight: bold;
                font-size: 20px;
                line-height: 26px;
                color: #000000;
                margin-top: 15px;
              }

              div:nth-child(2) {
                margin-top: 5px;
              }
              input[type='radio'] {
                display: none;
              }
              input[type='radio'] + label {
                color: #333333;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                div {
                  font-size: 18px;
                  line-height: 26px;
                  color: #333333;
                  margin-left: 7px;
                }
              }
              input[type='radio'] + label span {
                width: 14px;
                height: 14px;
                background-color: #d2d2d2;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                span {
                  width: 10px;
                  height: 10px;
                  background-color: #ffffff;
                  order-radius: 50%;
                }
              }
              input[type='radio']:checked + label span {
                width: 14px;
                height: 14px;
                background-color: #5fd2da;
                display: flex;
                justify-content: center;
                align-items: center;
                order-radius: 50%;

                span {
                  width: 4px;
                  height: 4px;
                  background-color: #ffffff;
                  order-radius: 50%;
                }
              }
            }
          }

          .prodoct-content {
            width: calc(100vw - 40px);
            margin: 0 auto;
            padding: 14px 12px 22px 12px;
            background-color: #f7f7f7;
            border-radius: 8px;
            box-sizing: border-box;

            .description {
              font-size: 14px;
              line-height: 20px;
              color: #999999;
            }
            .form {
              min-height: 210px;
              margin-top: 20px;
              border-top: 1px solid #c2c2c2;
              border-left: 1px solid #c2c2c2;
              border-right: 1px solid #c2c2c2;

              div {
                float: left;
                font-size: 14px;
                color: #666666;
                padding: 10px;
                border-bottom: 1px solid #c2c2c2;
              }
              div:nth-child(2n + 1) {
                width: 60%;
                box-sizing: border-box;
                border-right: 1px solid #c2c2c2;
                background-color: #e2e2e2;
              }
              div:nth-child(2n) {
                width: 40%;
                box-sizing: border-box;
              }
            }
          }

          .bottom-block {
            width: calc(100vw - 40px);
            background-color: #ffffff;
            box-shadow: 0px 10px 15px 10px #e0e0e0;
            padding: 20px;
            position: fixed;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .plus-minus {
              display: flex;
              justify-content: space-between;
              align-items: center;
              div {
                max-width: 80px;
                min-width: 60px;
                height: 30px;
                border: 1px solid #ebeae8;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 6px;
              }
            }
            .cart-button {
              width: 156px;
              height: 60px;
              background-color: #5fd2da;
              border-radius: 30px;
              color: #ffffff;
              font-size: 20px;
              line-height: 29px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .cart-showDetail {
            /* Frame 6 */
            box-sizing: border-box;
            /* Auto layout */
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 15px 0 0 20px;
            padding: 8px 16px;
            gap: 10px;

            width: calc(100vw - 40px);
            height: auto;
            left: 15px;
            top: 390px;

            background: #fdf8e4;
            border: 1px solid #f2ecd2;
            border-radius: 8px;
            .showcontent {
              width: 100%;
              height: 90%;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;

              color: #3b3516;

              /* Inside auto layout */

              flex: none;
              order: 0;
              flex-grow: 0;
            }
          }
        }
      `}</style>
    </>
  );
};

export default ProductDetail;
